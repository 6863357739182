import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ViewAll = styled.p`
  a {
    text-decoration: none;
    border-bottom: 2px solid #ff4136;
    color: rgba(0, 0, 0, 0.8);
  }
`

export default function ViewAllMessage() {
  return (
    <ViewAll>
      <Link to="/">View all posts</Link>
    </ViewAll>
  )
}
