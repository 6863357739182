import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import PostFooter from "../components/postFooter"
import styled from "styled-components"
import ViewAllMessage from "../components/viewAll"
import Seo from "../components/seo"

const BlogPostContent = styled.div`
  a {
    text-decoration: none;
    border-bottom: 2px solid #ff4136;
    color: rgba(0, 0, 0, 0.8);
  }
`

const PostDate = styled.h6`
  margin: 0.7rem 0;
  font-weight: 400;
`

export default function Template({ data }) {
  const { excerpt, frontmatter, html } = data.blogPost
  return (
    <Container>
      <Seo
        title={frontmatter.title}
        description={excerpt}
        image={frontmatter.image.childImageSharp.resize}
        pathname={frontmatter.path}
      />
      <ViewAllMessage />
      <h1>{frontmatter.title}</h1>
      <PostDate>{frontmatter.date}</PostDate>
      <BlogPostContent
        dangerouslySetInnerHTML={{ __html: html }}
      ></BlogPostContent>
      <PostFooter recommended={data.recommended} />
    </Container>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!, $recommended: String) {
    blogPost: markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        recommended
        image: featuredImage {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }

    recommended: markdownRemark(frontmatter: { path: { eq: $recommended } }) {
      frontmatter {
        path
        title
      }
    }
  }
`
