import React, { Fragment } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ViewAllMessage from "./viewAll"

const Recommended = styled.span`
  a {
    text-decoration: none;
    border-bottom: 2px solid #ff4136;
    color: rgba(0, 0, 0, 0.8);
    font-style: italic;
  }
`

const FooterContent = ({ recommended }) => {
  const { path, title } = recommended.frontmatter
  return (
    <div>
      <p>
        If you liked this post, might I recommend -{" "}
        <Recommended>
          <Link to={path}>{title}</Link>
        </Recommended>
      </p>
      <hr />
    </div>
  )
}

export default function PostFooter({ recommended }) {
  return (
    <Fragment>
      <hr />
      <div>
        {recommended ? <FooterContent recommended={recommended} /> : null}
        <ViewAllMessage />
      </div>
    </Fragment>
  )
}
